// Font Styles //

$body-font-size: 18px;
$body-font-family: Helvetica Neue, Helvetica, Arial, Sans-Serif;

$body-font-color: #000;

$h1-color: #6F6F6F;
$h2-color: #6F6F6F;
$h3-color: #6F6F6F;

$app-color: #89B7B7;

$ifm-app-color: #1d4269;
$eds-app-color: #eb7550;

$spacing: 10px;
$half-spacing: ($spacing / 2);

$sub-title-color: #6F6F6F;
$header-border-color: #fff;

$hyperlink-color: #037AFF;
$hyperlink-hover-color: #00abe2;

$title-background-color: #72B4B4;

// End Font Styles //

$grey-background: #f7f7f1;
$grey-border: #D0D0C4;
$default-input-border-color: #E4E4E4;
$default-border-color: #ebe9e9;
$white-color: #fff;
$label-color: #000;
$detail-input-color: #560E07;

$header-background-color: #BF9CC9;
$page-header-background-color: #60365c;

// Content //

$panel-section-background-color: #EDEDF0;
$panel-section-title-color: #534E70;
$list-wrapper-title-color: #333;

// End Content //


// Navigation //

$primary-navigation-color: #fff;
$navigation-link-selected-background-color: #fff;
$navigation-link-selected-color: #000;
$navigation-selected-background-color: #fff;
$navigation-background-color: #4C2246;
$navigation-border-color: #E4E4D9;

// End Navigation //


// Status Colours //

$empty-color: #D0021B;
$populated-color: #FF7C31;

// End Status Colours //

// User Profile Colours //

$profile-image-border-color: #fff;

// End User Profile Colours //

// Messages //

$message--error-border-color: #c34749;
$message--error-text-color: #c34749;
$message--error-background-color: #f3ebee;

$success-message-text-color: #657e3c;
$success-message-background-color: #edfdd3;
$success-message-border-color: #c4dba0;

$warning-message-text-color: #956433;
$warning-message-background-color: #fff7d6;
$warning-message-border-color: #e9d477;

$notice-message-text-color: #3a87ad;
$notice-message-background-color: #d9edf7;
$notice-message-border-color: #b7dbed;

$delete-message-text-color: #e18000;
$delete-message-background-color: #fdf2df;
$delete-message-border-color: #f59123;

// End Messages //

// Digital Channel Value Colours //

$digital-on-color: rgb(44, 194, 44);
$digital-off-color: rgb(245, 29, 29);

// End Digital Channel Value Colours //
