$my-color: rgb(235, 250, 235);

.graphs-container {
  padding-right: 20px;
  position: relative;
  z-index: 0;
}

.graph-container {
  margin: 20px 0 0 0;
  padding: 10px;
  overflow: hidden;
  position: relative;

  > div {
    width: 100% !important;
    // width: calc(100% - 21px) !important;     // DC: This seemed to have the correct effect on the first graph but all the rest were narrower than the first.
  }
}

.loading,
.graph-loading {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.7) url(../images/ajax-loader.gif) no-repeat center center;
}

.dygraph-axis-label-y {
  margin-top: -2px;
}

.graph-div.digital-graph {
  .dygraph-axis-label-y {
    margin-top: -4px;
    direction: rtl;
    font-size: 75%;
    white-space: nowrap;
  }
}

.dygraph-axis-label-x {
  margin-top: 2px;
}

.hairline {
  &-info {
    z-index: 11;  /* should appear on top of the chart */
    padding: 7px;
    min-height: 8px;
    background: #2C4141;
    font-size: 14px;
    cursor: move;
    width: 150px;
    margin-left: -81px;

    margin-bottom: 10px;
    color: #fff;

    &.fixed {
      cursor: default;
      // pointer-events: none;
    }

    &:after {
      position: absolute;
      bottom: -9px;
      left: 50%;
      content:"";
      display: block;  /* shrink to fit */
      z-index: 11;  /* should appear on top of the chart */
      cursor: move;

      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid #2C4141;
      margin-left: -9px;
    }

    &.fixed:after {
      cursor: default;
      // pointer-events: none;
    }

    &--channel {
      &:hover {
        opacity: 0.7;
      }
    }

    &--distance {
      background-color: #2C4141;
      border: 1px solid #2C4141;
      margin-left: 0;

      &:after{
        display: none;
      }

      &.right-flip {
        margin-left: -165px;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &--search,
    &--event {
      background-color: rgba(255,255,255, 0.5);
      color: #000;
      border: 1px solid #2C4141;
      margin-left: -126px;
      width: 250px;

      .hairline-kill-button {
        background: url(../images/event_hairline_remove_icon.svg) no-repeat center center / 12px 12px;
      }

      &:after {
        bottom: -10px;
        margin-left: -16px;
        height: 11px;
        width: 18px;
        border: none;
        background: url(../images/event_arrow.svg) no-repeat center center / 18px 11px;
      }
    }

    &--trigger {
      background-color: rgba(255, 255, 255, 0.5);
      color: #000;
      border: 2px solid #000;
      border-right-width: 10px;
      margin-left: -242px;
      padding-right: 20px;
      width: 215px;
      font-size: 16px;
      padding: 8px;

      .hairline-kill-button {
        background: url(../images/event_hairline_remove_icon.svg) no-repeat center center / 12px 12px;
      }

      &:after {
        display: none;
      }
    }

    &__lat-long-link {
      margin-top: 7px;
      padding: 5px 0 2px 0;
      text-align: center;
      border-top: 1px solid #fff;
      color: #fff;
      text-decoration: underline;
      display: block;
    }
    &--search &__lat-long-link,
    &--event &__lat-long-link,
    &--trigger &__lat-long-link {
      border-top: 1px solid #000;
      color: #000;
    }
  }

  &-kill-button {
    height: 16px;
    width: 16px;
    background: url(../images/hairline_remove_icon.svg) no-repeat center center / 12px 12px;
    text-indent: -9999px;
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    outline: none;
    padding: 0;
    pointer-events: auto;
    cursor: pointer;
  }

  &__channel + &__channel{
    margin-top: 5px;
  }

  &__channel-colour {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    text-indent: -9999px;
  }
}

.dygraph-title {
  text-align: center;
  font-size: 20px;
  color: #6F6F6F;
}

.dygraph-hairline {
  /* border-right-style: dotted !important; */
  cursor: move;
  pointer-events: none;
}

.dygraph-hairline.selected div {
  left: 2px !important;
  width: 2px !important;
}

.hairline-info.selected {
  border: 2px solid black;
  padding: 2px;
}

.additional-objects-list {
  color: #4e4e4e;
}
