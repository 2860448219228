@import '_variables';
@import '_mixins';

html {
  height: 100%;
}

html,
body {
  position: relative;
}

body {
  min-height: 100%;
  box-sizing: border-box;
  font: #{$body-font-size}/1.3 $body-font-family;
  font-weight: normal;
  padding-bottom: 43px;
  position: relative;
  color: $body-font-color;
  margin: 0;
}

a {
  display: inline-block;
  color: $hyperlink-color;
  text-decoration: none;
}

b {
  font-weight: 600;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  display: inline-block;
}

p {
  margin-top: 0;
  color: #6f6f6f;
  font-size: 20px;
  margin-bottom: 25px;
}

.right {
  float: right;
}

.left {
  float: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-decoration-none {
  text-decoration: none;
}

.block {
  display: block;
}

.hidden {
  display: none !important;
}

@mixin inline-block() {
  display: inline-block;
}

.inline-block {
  @include inline-block;
}

.vertical-middle {
  vertical-align: middle;
}

.vertical-bottom {
  vertical-align: bottom;
}

.vertical-top{
  vertical-align: top;
}

.clear {
  clear: both;
}

.overflow-hidden {
  overflow: hidden;
}

.relative {
  position: relative;
}

.default-border-radius {
  @include border-radius;
}

.padded {
  padding: $spacing;

  &--double {
    padding: $spacing * 2;
  }
}

.padded-top {
  padding-top: $spacing;
}

.padded-left {
  padding-left: $spacing;
}

.half-padded-top {
  padding-top: $half-spacing;
}

.padded-bottom {
  padding-bottom: $spacing;
}

.half-padded-bottom {
  padding-bottom: $half-spacing;
}

.padded-vertical {
  padding-top: $spacing;
  padding-bottom: $spacing;
}

.half-padded-vertical {
  padding-top: $half-spacing;
  padding-bottom: $half-spacing;
}

.padded-horizontal {
  padding-left: $spacing;
  padding-right: $spacing;
}

@mixin half-padded-top() {
  padding-top: $half-spacing;
}

.half-padded-horizontal {
  padding-left: $half-spacing;
  padding-right: $half-spacing;
}

.half-margin-bottom {
  margin-bottom: $half-spacing;
}

.margin-bottom {
  margin-bottom: $spacing;
}

.margin-top {
  margin-top: $spacing;
}

.margin-horizontal {
  margin-left: $spacing;
  margin-right: $spacing;
}

.margin-vertical {
  margin-top: $spacing;
  margin-bottom: $spacing;

  &--triple {
    margin-top: $spacing * 3;
    margin-bottom: $spacing * 3;
  }
}

.half-margin {
  margin: $half-spacing;
}

.half-margin-top {
  margin-top: $half-spacing;
}

.margin-left {
  margin-left: $spacing;
}

.half-margin-left {
  margin-left: $half-spacing;
}

.half-margin-right {
  margin-right: $half-spacing;
}

.margin-right {
  margin-right: $spacing;

  &--double {
    margin-right: $spacing*2;
  }
}

h1, h2, h3, h4 {
  margin-top: 0;
  font-weight: 400;

  &.no-margin {
    margin: 0;
  }
}

h1 {
  font-size: 28px;
  line-height: 1.25;
  color: $h1-color;
  overflow: hidden;
  padding-bottom: $spacing;
  margin-bottom: (2 * $spacing);
  border-bottom: 2px solid #EBE9E9;

  .action-button {
    margin-top: 5px;
  }
}

h2 {
  font-size: 30px;
  color: $h2-color;
  font-weight: 300;
}

h3 {
  font-size: 26px;
  color: $h2-color;
  font-weight: 300;
}

h4 {
  font-size: 20px;
  color: $h2-color;
  font-weight: 300;
  margin-bottom: 0.85em;
}

.meta-details {
  font-size: 18px;
  color: $body-font-color;
  vertical-align: baseline;
  @include inline-block;
  margin-left: 30px;
  font-weight: 300;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}