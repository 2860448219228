@import '_mixins';
@import '_variables';

.email-list {
  &__email {
    display: inline-block;
    line-height: 1.25;
    font-size: 16px;
    padding: 7px 30px 7px 10px;
    border: 1px solid $default-border-color;
    @include border-radius(5px);
    margin: 5px;
    position: relative;
  }

  &__remove {
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -13px;
    background-image: none;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: inline-block;
    padding: 5px;
  }

  &__selected-container {
    box-sizing: border-box;
    line-height: 1.25;
    padding: 0 0 7px 5px;
    border: 1px solid $default-border-color;
    max-width: 475px;
    min-height: 36px;
    margin-top: 5px;
    @include border-radius(5px);
    max-height: 107px;
    overflow-y: auto;
  
    &--tall {
      @extend .email-list__selected-container;
      max-height: 472px;
      overflow-y: auto;
    }
  }

  &__input {
    min-width: 475px;
    vertical-align: middle;
  }
}