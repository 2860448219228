@import '_mixins';

.export-choice {
  &__label {
    width: 540px;
    padding: 120px 40px 20px 40px;
    border: 1px solid #979797;
    @include border-radius(4px);
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;


    &--csv {
      background: url(../images/csv_icon.svg) no-repeat center 20px;
    }

    &--pdf {
      background: url(../images/pdf_icon.svg) no-repeat center 20px;
    }
  }

  input[type=radio] {
    display: none;
  }

  input[type=radio]:checked + span {
    background-color: #EFFBFF;
    border-color: #00ABE2;
  }
}

#export-email {
  width: 450px;
}