.graph-summary {
	// flex: 0 0 260px;
	flex: 0 0 325px;
  border-right: 1px solid $default-border-color;
  position: sticky;
  top: 0;
  align-self: flex-start;
  z-index: 2;
  padding-bottom: 40px;

  &__title {
    font-size: 20px;
    font-size: 1.2;
    padding-top: 10px;
    padding-bottom: 10px;
    padding: 10px 15px 10px 20px;
    color: #6F6F6F;
    background-color: #FDFCFC;
  }

  &__item {
    font-size: 15px;
    line-height: 1.2;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden;
    min-height: 18px;
    position: relative;

    &:nth-of-type(odd)
    {
      background-color: #fff;
    }

    &:nth-of-type(even)
    {
      background-color: #f6f6f6;
    }

    &--overflow-visible{
      overflow: visible;
    }

    &--current &-value,
    &--current &-label
    {
      font-weight: bold;
    }

    &-value,
    &-label
    {
      box-sizing: border-box;
    }

    &-value
    {
      width: 38%;
      text-align: right;
      color: #000;
      padding-left: 10px;
      padding-right: 15px;
      float: right;

      .digital-on
      {
        font-weight: bold;
        // color: $digital-on-color;
      }
      // .digital-off
      // {
      //   font-weight: bold;
      //   // color: $digital-off-color;
      // }

      &--italic {
        font-style: italic;
      }
    }

    &-label
    {
      float: left;
      width: 62%;
      font-style: italic;
      color: #6f6f6f;
      padding-left: 20px;
      padding-right: 10px;
    }

    &--timestamp &-value
    {
      width: 55%;
    }
    
    &--timestamp &-label
    {
      width: 45%;
      font-style: normal;
    }

    &-channel-colour-indicator
    {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 10px;
      height: 100%;
    }
  }
}
