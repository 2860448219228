@import '_mixins.scss';
@import '_variables.scss';

.react-tabs {
  &__tab-list{
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__tab-panel {
    display: none;

    &--selected{
      display: block;
    }
  }
}

.graphtab-wrapper
{
  position: relative;
  clear: right;

  .react-tabs {
    &__tab-list{
      top: -58px;
      left: 0;
      overflow: hidden;
      position: absolute;
    }

    &__tab {
      float: left;
      cursor: pointer;
      color: #fff;
      font-size: 16px;
      background-color: #5F8F8F;
      border-radius: 8px 8px 0 0;
      background-image: linear-gradient(-180deg, #5F8F8F 0%, #2C4141 100%);
      padding: 15px 50px;
      text-transform: uppercase;
      line-height: 1.125;
      margin: 0 1px;
      position: relative;
      margin-top: 10px;

      &:first-of-type {
        margin-left: 0;
      }

      &--selected {
        padding: 20px 45px;
        background:  $title-background-color;
        margin-top: 0;
      }
    }

    &__tab-panel {
      border: 1px solid $title-background-color;
    }
  }
}

.channel-select-tab-wrapper
{
  .react-tabs {
    &__tab-list{
      background-color: #DCECEC;
      overflow: hidden;
    }

    &__tab {
      float: left;
      cursor: pointer;
      color: #3C3C3C;
      font-size: 20px;
      padding: 15px 35px;
      text-transform: uppercase;
      line-height: 1.125;
      margin: 0 1px;

      &:first-of-type {
        margin-left: 0;
      }

      &--selected {
        background:  #fff;
        margin-top: 0;
      }
    }

    &__tab-panel {
      padding: $spacing * 1.5;
      clear: both;
    }
  }
}