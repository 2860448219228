@import '_mixins';
@import '_variables';

.wheel-diameter {
  text-align: left;
  position: absolute;
  box-sizing: border-box;
  padding: $spacing*1.5 $spacing *2;
  left: 344px;
  top: -40px;
  width: 270px;
  height: 310px;
  background-color: #fff;
  @include border-radius(3px);
  border: 1px solid $default-border-color;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  &:before {
    content: "";
    position: absolute;
    top: 50px;
    left: -21px;
    display: block;
    width: 21px; 
    height: 27px;
    background: url(../images/wheel_diamater_panel_arrow.svg) no-repeat center center;
  }

  &__permanent-text {
    font-style: italic;
    font-size: 12px;
  }

  &__title {
    margin-bottom: $spacing*1.5;
    font-size: 18px;
    color: #000;
  }

  & &__reset {
    font-size: 14px;
  }
}

input[type=number].wheel-diameter__input {
  max-width: 185px
}

.cancel-button {
  border: none;
  cursor: pointer;
  color: #000;
  padding: 10px 0 10px 30px; 
  background: transparent url(../images/cancel-icon.svg) no-repeat left 10px center;
  font-size: 14px;
}