@import '_variables';
@import '_mixins';

$title_height:  76px;
$button_action_height: 86px;

.ReactModal
{
  &__Overlay
  {
    z-index: 999;
    background: rgba(0, 0, 0, 0.5) !important;
  }

  &__Content
  {
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    @include border-radius(0 0 8px 8px);
    width: 1265px;
    padding-bottom: 86px;
    padding-top: 66px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow: hidden;

    &.dialogue--delete
    {
      padding-top: 0;
    }
  }

  &__title
  {
    background-color: $title-background-color;
    font-size: 28px;
    color: #fff;
    padding: 15px 20px;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
  }

  &__actions-wrapper
  {
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #EFEFEF;
    padding: ($spacing * 2);
  }
}