@import '_variables';

// Messages //

$error-message-border-color: #c34749;
$error-message-text-color: #c34749;
$error-message-background-color: #f3ebee;

$success-message-text-color: #657e3c;
$success-message-background-color: #edfdd3;
$success-message-border-color: #c4dba0;

$warning-message-text-color: #956433;
$warning-message-background-color: #fff7d6;
$warning-message-border-color: #e9d477;

$notice-message-text-color: #3a87ad;
$notice-message-background-color: #d9edf7;
$notice-message-border-color: #b7dbed;

$delete-message-text-color: #e18000;
$delete-message-background-color: #fdf2df;
$delete-message-border-color: #f59123;

// End Messages //

// Messages //

.message {
    border: 1px solid #000;
    box-sizing: border-box;
    display: none;
    margin-top: 0.5em;
    padding: 7px $spacing;
    font-size: 16px;

    &.block
    {
      display: block;
    }
    
    &.inline-block
    {
      display: inline-block;
    }

    &.no-margin-top {
      margin-top: 0;
    }

    p
    {
        font-weight: 500;
        margin: 1.5em 0 0.5em 0;
        line-height: 1.75;

        &:first-of-type
        {
            margin: 0.5em 0;
        }
    }

    p:last-of-type
    {
        margin-bottom: 0;
    }

    &--no-top-margin
    {
      margin-top: 0;
    }

    &--error
    {
      background-color: $error-message-background-color;
      border-color: $error-message-border-color;
      color: $error-message-text-color;
    }

    &--success
    {
      background-color: $success-message-background-color;
      border-color: $success-message-border-color;
      color: $success-message-text-color;
    }

    &--warning
    {
      background-color: $warning-message-background-color;
      border-color: $warning-message-border-color;
      color: $warning-message-text-color;
    }

    &--notice
    {
      background-color: $notice-message-background-color;
      border-color: $notice-message-border-color;
      color: $notice-message-text-color;

      &--with-icon {
        min-height: 50px;
        padding-left: 45px;
        background: $notice-message-background-color url(../images/info_icon.svg) no-repeat 10px center/26px 26px;
        line-height: 33px;
      }
    }

    &--delete
    {
      @extend .message;
      color: $delete-message-text-color;
      border: 1px solid $delete-message-border-color;
      background-color: $delete-message-background-color;
    }
}

// End Messages //
