@import '_variables';
@import '_mixins';

.channel-list{
  width: 375px;
  height: 342px;
  overflow-y: scroll;

  &__wrapper{
    width: 375px;
    padding: 0 $spacing*1.5 $spacing*1.5;
    background-color: #E2E2E2;
    @include border-radius(5px);
    float: left;
  }

  &__search-input {
    width: 100%;
  }

  &__title
  {
    margin: 0.75em 0;
    color: #3C3C3C;
    font-size: 20px;
  }

  &__item {
    font-size: 15px;
    line-height: 1.2;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden;
    min-height: 18px;
    position: relative;
    color: #6f6f6f;

    &:hover {
      cursor: pointer;
    }

    &:nth-of-type(odd)
    {
      background-color: #fff;
    }

    &:nth-of-type(even)
    {
      background-color: #f6f6f6;
    }

    &-label,
    &-short-label{
      box-sizing: border-box;
    }

    &-label
    {
      box-sizing: border-box;
    }

    &-short-label
    {
      width: 30%;
      padding-left: 10px;
      padding-right: 15px;
      float: right;
    }

    &-label
    {
      float: left;
      width: 70%;
      padding-left: 20px;
      padding-right: 10px;
    }

    &-channel-colour-indicator
    {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 10px;
      height: 100%;
    }
  }
}

.selected-channels-drop
{
  background-color: #fff;
  width: 255px;
  border: 1px solid #979797;

  &__wrapper
  {
    float: left;
    margin-left: $spacing*1.5;
  }

  &__wrapper &__clear-graph-channels {
    font-size: 14px;
  }

  &__channel,
  &__slot
  {
    margin: 10px;
    height: 38px;
  }

  &__channel
  {
    border: 1px solid #979797;
    padding-left: 46px;
    padding-right: 30px;
    position: relative;
    background-color: #fff;

    &-name-wrapper
    {
      height: 38px;
      line-height: 34px;
    }

    &-name {
      line-height: 1.3;
      display: inline-block;
      vertical-align: middle;
      font-size: 15px;
      font-style: italic;
      color: #6F6F6F;
    }

    &-color-pick
    {
      position: absolute;
      left: 0;
      top: 0;
      width: 36px;
      height: 38px;
      cursor: pointer;
    }

    &-color,
    &-color-arrow{
      display: inline-block;
      vertical-align: middle;
    }

    &-color{
      width: 15px;
      height: 100%;
      margin-right: 1px;
    }

    &-color-arrow{
      width: 17px;
      height: 38px;
      border-left: 1px solid #B3B3B3;
      background: url(../images/colour_pick_arrow.svg) no-repeat center center;
    }

    &-menu-icon{
      display: block;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      width: 19px;
      height: 38px;
      background: url(../images/graph_channel_menu_icon.svg) no-repeat center center;
    }

    &-menu
    {
      display: block;
      position: absolute;
      top: 38px;
      right: -1px;
      @include border-radius(4px 0 4px 4px);
      border: 1px solid #979797;
      width: 160px;
      background: #fff;
      z-index: 1;

      &-item{
        border: none;
        background: #fff;
        width: 100%;
        color: #6F6F6F;
        font-size: 15px;
        padding: $spacing;
        text-align: left;

        &:hover
        {
          background: #6F6F6F;
          color: #fff;
        }
      }
    }

    .color-picker-popout
    {
      position: absolute;
      z-index: 2;
      left: 0;
    } 
  }

  &--highlight{
    background-color: rgb(117, 211, 248);
  }

  &__slot
  {
    border: 1px dashed #979797;
    background-color: #fff;

    &:nth-child(even)
    {
      background-color: #f6f6f6;
    }
  }
}
