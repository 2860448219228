@import '_variables';
@import '_mixins';

$primary-navigation-color: #9B9B9B;
$navigation-link-selected-background-color: #fff;
$navigation-selected-background-color: #fff;
$previous-page-link-text-color: #fff;
$navigation-gradient-start: #fff;
$navigation-gradient-end: #edede8;
$navigation-border-color: #e4e4d9;


.navigation-gradient
{
	@include gradient($navigation-gradient-start, $navigation-gradient-end);
	border-bottom: 1px solid $navigation-border-color;
}

.primary-navigation
{
	  height: 58px;
    list-style-type: none;
    margin: 0;
    padding: 0;

    &__navigation-item
    {
	    padding-right: 2px;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAA7CAYAAAC+ATB/AAAANklEQVQoU2N4/fo178+fP3Y+fnxjG8OHDx8Ef/36+f7x4+vvRjmjnGHJOXPmDOvPnz/DgJxgABIIXIaZEhW1AAAAAElFTkSuQmCC') /*../Images/primary_navigation_item_bg.png*/ repeat-y right top;
      float: left;
    }

    &__navigation-link
    {
	    display: block;
	    padding: 20px 15px;
	    font-size: 14px;
	    text-decoration: none;
      cursor: pointer;
      color: $primary-navigation-color;
      text-transform: uppercase;
      
      &:hover,
      &.is-selected
      {
        background-color: $navigation-selected-background-color;
        padding-bottom: 21px;
      }
    }
}
