@import '_variables';

$header-meta-color: #fff;

.app-logo
{
	padding-top: 7px;
	padding-bottom: 6px;
	max-width: 190px;
}

.meta-section
{
	float: right;
	padding-left: 20px;
	margin-left: 20px;
	font-size: 14px;
	color: $header-meta-color;
	background: url(~images/meta_divider.png) repeat-y left top;
}

.page-content
{
	padding: 35px 15px 20px 15px;
}

.flexbox
{
	display: -webkit-flex;
  display: flex;
  
  &__item--same-size {
    flex: 1;
  }
}

.flex-end {
  align-self: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.chart-section
{
	flex: 1;
	background-color: #FDFCFC;
	box-sizing: border-box;
  padding: 0 0 20px 0;
  border-left: 1px solid $default-border-color;
  position: relative;
  left: -1px;
  z-index: 1;
}

.option-field
{
	width: 350px;
}

.cover
{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.status-icon {
  display: inline-block;
  height: 72px;
  width: 72px;

  &--success {
    background: url(../images/success_icon.svg) no-repeat center center/ 72px 72px;
  }

  &--error {
    background: url(../images/error_icon.svg) no-repeat center center/ 72px 72px;
  }
}


.highlight-panel {
  background-color: #F6F6F6;
}

.login-content-wrapper {
  background-color: transparent;
  margin-left: 0;
}

.login-content {
  box-sizing: border-box;
  padding: 0;
  position: relative;
  z-index: 1;

  .login {
    width: 475px;
    margin: 0 auto;

    .login-title {
      border: 0;
      margin: 0;
      background-color: $title-background-color;
      color: $white-color;
    }

    .login-form-container {
      border: 1px solid $grey-border;
      border-top: none;
    }
  }

  .link-button {
    display: block;
    margin: 2.0em auto;
    width: 200px;
    font-size: $body-font-size;
  }
}

.restricted-page-width {
  max-width: 1133px;
  margin: 0 auto;
}

.separator--top {
  border-top: 1px solid #979797;
}