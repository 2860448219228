@import '_mixins';

.no-data-panel {
  position: absolute;
  top: 0;
  left: 86px;
  height: calc(100% - 75px);
  background: rgba(255, 255, 255, 0.5);
  text-align: center;
  pointer-events: none;
  z-index: 12;
  font-size: 16px;
  
  &__text {
    padding: 20px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include border-radius(5px);
  }
}