.graph-picker {
  background-color:  rgb(245, 249, 255);
  padding: 10px;

  input {
    margin: 0 10px 10px 10px;
  }

  #vehicleId, #detailResolution, #channels {
    margin-bottom: 10px;
  }

  // https://react-select.com/upgrade-guide#new-styles-api
  // https://react-select.com/styles
  .select-channel {
    &__menu {
    }

    &__menu-list {
      // overflow: visible;
    }

    &__option {
      // background-color: aquamarine;
      display: inline-block;
      width: auto;
    }
  }
}

.select-language__value-container {
  background-color: #ddddff;
}
