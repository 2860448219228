@import '_variables';

.add-trigger-container{
  margin: 41px 31px;
}

.page-section{
  margin-bottom: 50px;
}

.trigger-container{
  background-color:#f3f3f3;
  padding: 33px 23px 70px 23px;
}

.condition-container{
  background-color:#f3f3f3;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  padding: 10px;
}

.selection-list{
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 5px;

  li {
    display: inline-block;
  }
}

.channel-selection
{
  margin-left: 20px;
  display: inline-block;
}

.operator-selection
{
  margin-left: 20px;
  display: inline-block;
}

.evaluation-selection
{
  margin-left: 20px;
  display: inline-block;
}

.condition-label
{
  font-size: 20px;
  color: #6F6F6F;
  display: inline-block;
  margin-bottom: 0;
  width: 70px;
  text-align: right;
  text-transform: uppercase;
}

.dropdown
{
  box-sizing: border-box;
  line-height: 1.25;
  font-size: 16px;
  padding: 7px 10px;
  border: 1px solid $default-border-color;
  min-width: 80%;

  @include border-radius(5px);

  &.error {
    border-color: red;
  }
}

.input-field
{
  margin-top: 10px;
}

.help-label
{
  font-size: 15px;
}

.field-label
{
  width: 200px;
  display: inline-block;
  vertical-align: top;
}

.condition-field-label
{
  display: inline-block;
  vertical-align: bottom;
  vertical-align: top;
  margin-top: 35px;
}

.field-input
{
  display: inline-block;
}

.condition-field-input
{
  display: inline-block;
  margin-left: 20px;
  vertical-align: top;

  &--error{
    padding-bottom: 50px;
  }

  .message--error {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 900px;
  }
}

.condition-two-field-input
{
  display: inline-block;
  margin-left: 20px;
}

.trigger-condition__remove {
  padding: 18px;
}

.column-third
{
  box-sizing: border-box;
  width: 33.33%;
  padding: 10px;
  display: inline-block;
}

.column-quarter
{
  box-sizing: border-box;
  width: 25%;
  padding: 10px;
  float: left;
}

.single-added-email
{
  line-height: 1.25;
  font-size: 16px;
  padding: 7px 30px 7px 10px;
  border: 1px solid $default-border-color;
  @include border-radius(5px);
  margin: 5px;
  position: relative;
}

.added-email-container
{
  box-sizing: border-box;
  line-height: 1.25;
  padding: 0 5px 7px 5px;
  border: 1px solid $default-border-color;
  max-width: 80%;
  min-height: 36px;
  margin-top: 5px;
  @include border-radius(5px);
}

.remove-email-button
{
  position: absolute;
  top: 50%;
  margin-top: -13px;
  right: 5px;
  background-image: none;
  background: none;
  border: none;
  padding: 0;
	cursor: pointer;
  outline: inherit;
  padding: 5px;
}

.pageSeperator
{
  border-bottom: 2px solid #EBE9E9;
}

.full-container
{
  max-width: 100%
}

.padded-5
{
  padding: 2%;
}
