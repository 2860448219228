@import '_variables';
@import '_mixins';

.preset-save {
  text-align: right;
  padding-top: 8px;
  padding-bottom: 6px;

  .invalid-preset & {
    padding-top: 7px;
  }

  label {
    color: #000;
    font-size: 15px;
    margin-bottom: 0;
    line-height: 36px;
  }

  label,
  input[type="checkbox"],
  input[type="text"] {
    display: inline-block;
    vertical-align: middle;
  }
}