.ReactTable
{
  .rt-thead {
    .rt-resizable-header {
      background-color: #64A6A6;
      color: #fff;
      padding: 10px;
      text-transform: uppercase;
      text-align: left;
    }

    .rt-th.-sort-asc,
    .rt-td.-sort-asc {
      box-shadow: inset 0 3px 0 0 rgba(46, 65, 65, 0.8)
    }

    .rt-tr {
      background-color: #64A6A6;
      text-align: left;
    }

    &.-filters input,
    &.-filters select {
      font-size: 16px;
      border-radius: 5px;
    }

    &.-filters input {
      padding: 7px 10px;
    }

    &.-filters .dropdown-select input {
      padding: 7px 10px;
      border: none;
      border-radius: 0;
    }

    .rt-th--overflow-visible{
      overflow: visible;
    }
  }

  .rt-tbody {
    .rt-td {
      font-size: 17px;
      padding-left: 10px;
      padding-right: 10px;
      align-self: center;

      &--overflow-visible {
        overflow: visible;
      }
    }
  }

  .-pagination {
    font-size: 16px;
  }
}