.Toastify__toast--success
{
  background: url(../images/success_icon.svg) no-repeat left 15px center/35px 35px #fff;
  padding-left: 75px;
  color: #000;
  font-size: 16px;
}

.Toastify__toast--error
{
  background: url(../images/error_icon.svg) no-repeat left 15px center/35px 35px #fff;
  padding-left: 75px;
  color: #000;
  font-size: 16px;
}

.Toastify__close-button
{
  opacity: 1;
  width: 12px;
  height: 12px;
  text-indent: -9999px;
  background: url(../images/event_hairline_remove_icon.svg) no-repeat center center / 12px 12px;
}