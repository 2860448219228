@import '_variables';
@import '_mixins';

.preset-select{
  @include border-radius(5px);
  border: 1px solid $default-border-color;
  position: relative;

  &__arrow {
    display: inline-block;
    vertical-align: middle;
    width: 44px;
    height: 34px;
    background: #fff url(../images/dropdown_arrow_icon_down.svg) no-repeat center center;
  }

  &--open &__arrow {
    background-image: url(../images/dropdown_arrow_icon_up.svg);
  }

  input[type=text] {
    border: none;
    width: 244px;
    vertical-align: middle;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    
    &:focus {
      outline: none;
    }
  }

  &-wrapper{
    width: 290px;
    position: relative;
    font-size: 16px;
  }

  &__option-panel {
    max-height: 280px;
    overflow-y: auto;
  }

  &__option{
    display: block;
    padding: 4px 5px;
    color: #4c4c4c;

    &:hover,
    &--selected {
      background-color: #72B4B4;
      color: #fff;
    }
  }

  &__option-wrapper{
    position: absolute;
    top: 35px;
    z-index: 1000;
    width: 100%;
    box-sizing: border-box;
    left: 0;
    background: #fff;
    border: 1px solid $default-border-color;
  }

  &__link{
    padding: 5px 7px;
    display: block;
    background: none;
    color: $body-font-color;
    border: none;
    cursor: pointer;
    font-size: 16px;
    box-sizing: border-box;
    width: 100%;
    text-align: left;

    &-wrapper{
      border-top: 1px solid $default-border-color;
      padding: 7px 0;
    }
  }
}