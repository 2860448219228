@import '_variables';
@import '_mixins';

// Profile Drop Down //

$header-meta-color: #fff;
$profile-link-color: #000;

.user-profile {
  &__navigation-expander {
    color: $header-meta-color;
    text-decoration: none;
    padding: 8px 18px 8px 0;
    cursor: pointer;
    //images/profile-link-arrow-down.png//
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAHCAYAAADebrddAAAABGdBTUEAALGPC/xhBQAAAFpJREFUGBlj+P///2wgJgbMZgCqYgfiUwRUg+TZGUAAyJAF4tc4NIDEZcEKYQRQwBmI/6BpAPGdYWpQaKBEGZriMhQF6Byg4jVQDWvQ5TD4QIW8QLwORKNLAgCXSrjE+tHtWgAAAABJRU5ErkJggg==') no-repeat right center;
    //images/profile-link-arrow-down.svg//;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjExcHgiIGhlaWdodD0iN3B4IiB2aWV3Qm94PSIwIDAgMTEgNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMzkuMSAoMzE3MjApIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPnByb2ZpbGUtbGluay1hcnJvdy1kb3duPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlnb24gaWQ9InByb2ZpbGUtbGluay1hcnJvdy1kb3duIiBmaWxsPSIjRkZGRkZGIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1LjUwMDAwMCwgMy41MDAwMDApIHNjYWxlKDEsIC0xKSByb3RhdGUoMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC01LjUwMDAwMCwgLTMuNTAwMDAwKSAiIHBvaW50cz0iMy41NTI3MTM2OGUtMTUgMS4zMzIyNjc2M2UtMTUgMTEgLTQuNDQwODkyMWUtMTYgNS41IDciPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+') no-repeat right center/11px 7px;

    &:hover {
      color: $header-meta-color;
    }
  }

  &__navigation-expander--hidden {
    color: $header-meta-color;
    text-decoration: none;
    padding: 8px 8px 8px 0;
  }

  &.expanded &__navigation-expander {
    //images/profile-link-arrow-up.png//
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAHCAYAAADebrddAAAABGdBTUEAALGPC/xhBQAAAGFJREFUGBljYEAD////5wXidSAaTQqTC1S0BohBYA2mLJIIUEEZWBmCKEOSRjCB8s5A/AehDswC8Z0RqoAsoIAsEL8GS2MSIHFZsAYggx2IT2GqQREBybODTJ2NIoybMxsA78a4xJYwUFUAAAAASUVORK5CYII=') no-repeat right center;
    //images/profile-link-arrow-up.svg//;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjExcHgiIGhlaWdodD0iN3B4IiB2aWV3Qm94PSIwIDAgMTEgNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMzkuMSAoMzE3MjApIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPnByb2ZpbGUtbGluay1hcnJvdy11cDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwb2x5Z29uIGlkPSJwcm9maWxlLWxpbmstYXJyb3ctdXAiIGZpbGw9IiNGRkZGRkYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUuNTAwMDAwLCAzLjUwMDAwMCkgcm90YXRlKDE4MC4wMDAwMDApIHRyYW5zbGF0ZSgtNS41MDAwMDAsIC0zLjUwMDAwMCkgIiBwb2ludHM9IjMuNTUyNzEzNjhlLTE1IDEuMzMyMjY3NjNlLTE1IDExIC00LjQ0MDg5MjFlLTE2IDUuNSA3Ij48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==') no-repeat right center/11px 7px;
  }

  &__navigation {
    margin: 0;
    background-color: $white-color;
    border: 1px solid $default-border-color;
    padding: 0 $spacing;
    display: none;
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    min-width: 100px;
    right: 0;
    top: 57px;
    z-index: 1;
    @include border-radius(7px);
    list-style-type: none;

    a {
      color: $profile-link-color;
      text-decoration: none;
    }

    //Used below rule as it doesn't apply to first item, rather than using '.last' class etc.
    li + li {
      border-top: 1px solid $default-border-color;
    }
  }
  
  &.expanded &__navigation {
    display: block;
  }
  
  &__image {
    height: 38px;
    width: 38px;
    border: 1px solid $profile-image-border-color;
    text-align: center;
    line-height: 35px;
    margin-right: 11px;
    //images/profile-image-background.png//;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAlCAMAAADyQNAxAAAAeFBMVEXNzMz////Z2dj9/v/9/fzn5eXOzs7Ozc3U09Pr6unX19bq6enj4uHV1NTv7u339/b4+Pjk4+Pe3t3Pz8/19fXX19f29vbn5uXU1NTp6Of8/Pvu7ezQ0M/i4eHr6enw7u7g39/a2tnz8vLOzcz19PPs6+rr6urs6+uBGV4bAAAAvklEQVR42uXSxw7CMAyAYbt70r33AN7/DRESgUSNm0OP/OdPthwF/i/PL3W99L0zY7n46WGRyJ7w22RTKkCugBoV8yomhjko5MhVKKrwyixLVMRbaDqPdE2uIhSKyIXqlXYrolb+YImoEpCX8igFooxXGVDxfwLItJ/SgO7O0BNOMpkyr6uKqYo2dWMwZTQ1ceANhW7HM/shx0P50POmcA2UZrgFM8uKJ63L22wjKho3gBmVzQCdWnUAu1rt8AJf2AgkZkf6PgAAAABJRU5ErkJggg==');
    //images/profile-image-background@2x.png//;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAABMCAMAAADwSaEZAAAAulBMVEXa2tn9/fzW1dXT09POzs7z8vLr6urOzs3k4+Ph4OD4+Pjw7+7w7u7e3t37/PzOzc3p6OfT0tLS0dHm5eXa2dnb2trc29v7+/rj4uLw7+/08/P19PP6+fjx7+/b29vb2tnn5eX19fTd3N3f3t7l5OTX19fq6en29vXn5uXl5OPs6+rZ2djo5uby8fHV1dXt6+vY2Njy8fDg397X19bu7ez19fXv7u708vL8/PvU1NTQ0NDg4N/////NzMzJXjyGAAABo0lEQVR4Ae3W5YKjMBSG4eLuVu+4u0/n4/4va92XHHpC1vf9iTxYAoxahf3H/mM/D/uPZd7JNAbi2YmXDcROQ3xReDoAy+/wTXe5LHY1x3fNr+SwUkNHWimD6TE6i3U+5mxD0LbDxsYQNuZijg9hc4eJLUG0ZGIpiFImtgDRgolZILJ+JRaAKGBiNohsJlaAqGBiLyDymFgFooqHZfQ9y1iYBTKLg+noSWdgN+jphoEdo6djBraFnrYYmIeePAZmoCeDMzRSkKWscWaGIApN5tdJP4SgQ53/Eb6EoMuWj40hyJXAbiHoVgJLfHTmJ2zsTYX4NcvH8u5fqlwKa/fQ0V4rhyUTfNcioTFiJb5r1P7Hfh62En9/+dgOvmtHFqvv8V33tQzm7M7Q2WzXYWJr14cw310zMP1AA5l2oG+GmdE1Nug6Mnuxi7MGG9acXVCYGZ2D1XlkCrDnowbsmqPnDmw1gWST1TfYcooBTasvMCPAwALjI1Y+YXBP5XtsX4OCtP132COU9PgWM6Ao4w1WQ1H1G8yFotw3mA1F2W+wByjqoX0Ne5I5Rn5BFPIAAAAASUVORK5CYII=') no-repeat center center/38px 38px;
    background-size: cover;
    @include border-radius(20px);
  }
}
