@import 'tabs.scss';
@import '_graph-summary.scss';
@import '_graph-test.scss';
@import '_buttons.scss';

.graph-tab-title {
  background: $title-background-color;
  padding: 15px 20px;
  position: relative;

  h1 {
    color: $white-color;
    border: none;
    padding: 0;
    margin: 0;
    line-height: 1.3;
  }
}

.chart-section .graph-navigation {
  border-bottom: 1px solid $default-border-color;
  position: relative;
  z-index: 1;
}

.zoom-play-area {
  background-color: #f6f6f6;
  padding: 25px 70px;
  border-right: 1px solid $default-border-color;
  flex: 0 0 216px;

  .detail-resolution {
    display: inline-block;
    width: 84px;
    padding: 0 20px;
    color: $sub-title-color;
    font-size: 16px;
    text-align: center;
  }

  .zoom-area {
    @include border-radius(3px);
    @include button-secondary-border;
    background-color: $white-color;
  }
}

.actions-toolbar {
  flex: 1;
  padding: 10px;

  &.right {
    padding-right: 20px;
  }
}

.range-selector-wrapper {
  width: 690px;
  position: relative;
  z-index: 10;
}