@import '_variables';
@import '_mixins';

.dropdown-select{
  @include border-radius(5px);
  border: 1px solid $default-border-color;
  position: relative;
  max-width: 360px;

  &--small {
    max-width: 180px;
  }

  &__arrow {
    @include border-radius(0 5px 5px 0);
    display: inline-block;
    vertical-align: middle;
    width: 44px;
    height: 34px;
    background: #fff url(../images/dropdown_arrow_icon_down.svg) no-repeat center center;
  }

  &--open &__arrow {
    background-image: url(../images/dropdown_arrow_icon_up.svg);
  }

  &--invalid {
    border: 1px solid red;
  }

  input[type=text] {
    border: none;
    width: calc(100% - 44px);
    vertical-align: middle;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:focus {
      outline: none;
    }
  }

  &--centered input[type=text] {
    text-align: center;
  }

  &-wrapper{
    // width: 290px;
    position: relative;
    font-size: 16px;
  }

  &__option-panel {
    max-height: 280px;
    overflow-y: auto;
  }

  &__option{
    display: block;
    padding: 4px 5px;
    color: #4c4c4c;

    &:hover,
    &--selected {
      background-color: #72B4B4;
      color: #fff;
    }
  }

  &__option-wrapper{
    position: absolute;
    top: 35px;
    z-index: 1000;
    width: 100%;
    box-sizing: border-box;
    left: 0;
    background: #fff;
    border: 1px solid $default-border-color;
  }
}

.dropdown {
  .prompt {
    color: #aaaaaa;
  }
}
