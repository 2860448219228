// Buttons //
@import '_mixins';
@import '_variables';

$default-button-color: #fff;
$default-button-background-color: #00ABE2;
$default-button-border-color: #0086B0;
$default-button-active-color: #0191BF;
$secondary-button-color: #969686;
$secondary-button-background-color: #fff;
$secondary-button-border-color: #D0D0C4;
$secondary-button-active-color: #D8D8D8;

$action-button-color: #9B9B9B;
$action-button-border-color: #E4E4D9;
$action-button-icon-active-background-color: #F9F9F9;
$action-button-active-background-color: #eee;
$action-button-hover-border-color: #E4E4D9;
$action-button-icon-background-color: #fff;

@mixin button-border()
{
	border: 1px solid $default-button-border-color;
}

@mixin button-secondary-border()
{
	border: 1px solid $secondary-button-border-color;
}

.button {
  @include border-radius(3px);
  @include button-border;

	display: inline-block;
	color: $default-button-color;
	background-color: $default-button-background-color;
	text-decoration: none;
  vertical-align: middle;
  width: auto;
  font-size: 16px;
  line-height: 20px;
  padding: 12px 20px;
  font-weight: 400;
  cursor: pointer;

  &.vertical-bottom
  {
      vertical-align: bottom;
  }

  &--disabled {
    opacity: 0.4;
    cursor: default;

    &:active,
    &:hover,
    &.open
    {
      background-color: $default-button-background-color;
      color: $default-button-color;
      @include button-border;
      box-shadow: none;
    }
  }

  &__full-width {
    width: 100%;
  }
}

.button--small
{
    font-size: 12px;
    line-height: 15px;
    padding: 5px 10px 4px 10px;
}

.button--secondary
{
	color: $secondary-button-color;
	background-color: $secondary-button-background-color;
	@include button-secondary-border;

  &.arrow-right
  {
      padding-right: 30px;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQCAYAAADESFVDAAAAlUlEQVR4AWOYP79LgoEQmD69/cb06V3WeBVNm9b+bvr0tp/TprXF41UEVPAfhIGKO////8+EUxECt29atWoqD05FCBPbL82Z0yOPqQgTvwJ5iJCi/zAPwRXhwZPwKfo9Y0Z7Bh6Ht70FOt4Jp8NBMTBnTrsKniBo3zl/fr8AvsCctGrVKmZcEfwS7EB8YObMNnNCKQUAufjSVLLecIkAAAAASUVORK5CYII=') no-repeat right 12px center $secondary-button-background-color; //../../Images/secondary_button_arrow__right.png//
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgOSAxNiI+CiAgPHBvbHlnb24gZmlsbD0iIzk2OTY4NiIgZmlsbC1ydWxlPSJldmVub2RkIiBwb2ludHM9IjEyLjA2OCAyOS42IDEyLjA2OCAxNC42IDIxLjA2OCAyMi4xIiB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAtMSAtMTIgMzAuMikiLz4KPC9zdmc+Cg=='); //../../Images/secondary_button_arrow__right.svg//
  }

  &.arrow-left
  {
      padding-left: 30px;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAQCAYAAAAvf+5AAAAAoUlEQVR4AWMgBObM6RNatWoVG15F06Z1qk+b1n4biAVxKpo5s91t2rS290D8H6fCGTM6cqdPb/8DUoRV4f79+1mAgtMRChAKURwNNGUvXBJTIcLRCAlMhSiOxqsQaF0xkPOXkEKYL32BAp/xKkSEW4cuUPABHoUIMG/eJNHp09uO4FGIAKB4Bbp7AQGFCACULAV5kpBCFE8SUIjw5KpVfZwAq5LpmNuf4uAAAAAASUVORK5CYII=') no-repeat left 12px center $secondary-button-background-color; //../Images/secondary_button_arrow__left.png//
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEwIDE2Ij4KICA8cG9seWdvbiBmaWxsPSIjOTY5Njg2IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHBvaW50cz0iMTIuMDY4IDI5LjYgMTIuMDY4IDE0LjYgMjEuMDY4IDIyLjEiIHRyYW5zZm9ybT0icm90YXRlKC0xODAgMTAuNTY4IDE1LjEpIi8+Cjwvc3ZnPgo='); //../Images/secondary_button_arrow__left.svg//
  }

  &.range-search-button
  {
    margin-top: 0;
    padding-left: 45px;
    background: $action-button-icon-background-color url(../images/time-left-grey.svg) no-repeat 10px center / 25px 25px;
  }
}

input.button[type=submit],
input.button[type=button]
{
	padding-top: 0;
	padding-bottom: 0;
	height: 43px;
  box-sizing: border-box;
}

input.button--small[type=submit],
input.button--small[type=button]
{
	height: 26px;
}

.button:active,
.button:hover,
.button.open
{
  background-color: $default-button-active-color;
  color: $default-button-color;
  @include button-border;
  box-shadow: none;
}

.button--secondary:active,
.button--secondary:hover
{
	background-color: $secondary-button-active-color;
  border-color: $secondary-button-active-color;
  color: $secondary-button-color;
}

.button--secondary.button--disabled:active,
.button--secondary.button--disabled:hover
{
	color: $secondary-button-color;
	background-color: $secondary-button-background-color;
	@include button-secondary-border;
}

.action-button
{
	display: inline-block;
  overflow: hidden;
  @include gradient(#fff, #edede8);
  border: 1px solid $action-button-border-color;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;

  &__icon
  {
		background-color: $action-button-icon-background-color;
		height: 34px;
		width: 34px;
		border-right: 1px solid $action-button-border-color;
		background-repeat: no-repeat;
		background-position: center top;
    float: left;
	}

  &__action
  {
		padding: 0 13px;
		color: $action-button-color;
		text-transform: uppercase;
		line-height: 34px;
    float: left;
	}

  &__icon,
  &__action
  {
    display: inline-block;
    vertical-align: middle;
  }

  &:hover,
  &:active
  {
    background: $action-button-active-background-color;
    border: 1px solid $action-button-hover-border-color;

    .action-button__icon
    {
      background-color: $action-button-icon-active-background-color;
      border-right: 1px solid $action-button-hover-border-color;
    }
  }

  &--chart
  {
    .action-button__icon
    {
      background: $action-button-icon-background-color url(../images/chart_button_icon.svg) no-repeat center center/17px 17px; // ../../images/action-add-icon.svg //
    }
  }
  
  &--export
  {
    .action-button__icon
    {
      background: $action-button-icon-background-color url(../images/export_icon.svg) no-repeat center center/17px 17px; // ../../images/action-add-icon.svg //
    }
  }

  &--share
  {
    .action-button__icon
    {
      background: $action-button-icon-background-color url(../images/share_icon_grey.png) no-repeat center center/17px 17px; // ../../images/action-add-icon.svg //
    }
  }
}

.link-button
{
  display: inline-block;
  background: none;
  color: $hyperlink-color;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 16px;
}

.icon-button
{
  background: none;
  color: $hyperlink-color;
  border: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  text-indent: -9999px;

  &--view {
    width: 36px;
    height: 27px;
    background: url(../images/view_icon.svg) no-repeat center center;
  }

  &--delete {
    width: 28px;
    height: 33px;
    background: url(../images/delete_icon.svg) no-repeat center center;
  }
  
  &--edit {
    width: 32px;
    height: 32px;
    background: url(../images/edit_icon.svg) no-repeat center center;
  }
  
  &--remove {
    width: 24px;
    height: 24px;
    background: url(../images/remove_icon.svg) no-repeat center center;
  }

  &--add {
    @include border-radius(5px);
    width: 36px;
    height: 36px;
    margin-left: 10px;
    border: 1px solid $action-button-border-color;
    background: url(../images/tick_icon.svg) no-repeat center center, linear-gradient(#fff, #edede8);
  }
}

.zoom-button
{
  display: inline-block;
  vertical-align: middle;
  padding: 22px;
  border-color: $secondary-button-border-color;
  border-style: solid;
  
  &.zoom-in
  {
    background: $action-button-icon-background-color url(../images/zoom-in.svg) no-repeat 10px center / 25px 25px;
    border-radius: 0 3px 3px 0;
    border-width: 0 0 0 1px;
  
    &.disabled, .disabled:hover
    {
      background-image: url(../images/zoom-in-disabled.svg);
    }
  }

  &.zoom-out
  {
    background: $action-button-icon-background-color url(../images/zoom-out.svg) no-repeat 10px center / 25px 25px;
    border-radius: 3px 0 0 3px;
    border-width: 0 1px 0 0;
  
    &.disabled, .disabled:hover
    {
      background-image: url(../images/zoom-out-disabled.svg);
    }
  }

  &:hover
  {
    background-color: #ddd;
  }
  
  &.disabled, .disabled:hover
  {
    background-color: $white-color;
    cursor: default;
  }
}
.button--hairline-action {  
  background-color: #457C7C;
  padding-left: 45px;
  border-color: #2B6666;
  outline: none;
  box-sizing: border-box;
  width: 210px;
  text-align: center;
  padding-top:7px;
  padding-bottom: 7px;

  &:hover {    
    background-color: #457C7C;
    border-color: #2B6666;
  }

  &.on {
    background-color: #5FA2A2;
    box-shadow: inset 2px 2px 3px 0 rgba(0,0,0,0.50);    
  }
}

.button--calculate-distance {  
  background-image: url(../images/difference_icon.svg);
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 21px 25px;
}

.button--add-hairline {  
  background-image: url(../images/hairline_icon.svg);
  background-repeat: no-repeat;
  background-position: 14px center;
  background-size: 15px 19px;
}

.range-search-button {
  margin-top: 26px;
}

.backIfmBlue {
  background-color: $ifm-app-color;
  color: $white-color;
}

.backEdsOrange {
  background-color: $eds-app-color;
  color: $white-color;
}