@import '_mixins';

$footer-background-color: #edede8;
$footer-border-color: #fff;

.footer {
  background-color: $footer-background-color;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  &__meta-section {
    float: left;
    padding-right: 20px;
    margin-right: 20px;
    font-size: 14px;
    color: #9B9B9B;
    background: url(~images/footer_meta_divider.png) repeat-y right top;
    
    &:last-of-type{
      background: none;
    }

    > a,
    > span {
      text-decoration: none;
      display: inline-block;
      padding: 12px 0;
    }

    &--right {
      float: right;
      padding-right: 0;
      margin-right: 0;
      padding-left: 20px;
      margin-left: 20px;
      background: none;
    }
  }

  // needs a before
  &__logo-link {
    padding: 20px 0;
  }
}
