@import '_variables';
@import '_mixins';

.advanced-search{
  &-wrapper{
    width: 450px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    color: #fff;
    font-size: 16px;

    &.fixed {
      position: fixed;
      left: 16px;
    }
  }

  &,
  &-handle {
    background-color: #2C4141;
  }

  &-handle {
    padding: 10px 65px 10px 15px;
    display: inline-block;
    @include border-radius(4px 4px 0 0);
    background: #2C4141 url(../images/search-tab-arrow__up.svg) no-repeat right 15px center;

    &.open {
      background-image: url(../images/search-tab-arrow__down.svg);
    }
  }

  & {
    padding: 15px;
    position: relative;

    label,
    .label {
      color: #fff;
      font-size: 16px;
    }
  }

  .message {
    font-size: 12px;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  &__value-error {
    margin-left: 110px;
  }

  &__results-header-container {
    background-color: #457C7C;
    overflow: hidden;
  }

  &__results-header-container,
  &__results-container {
    font-size: 14px;
  }

  &__results-header {
    & + & {
      border-left: 1px solid #000;
    }
  }
  &__results-column {
    font-size: 13px;
    color: #000;
  }

  &__results-column,
  &__results-header {
    box-sizing: border-box;
    padding: 5px 10px;
    display: inline-block;
  }

  &__results-column--start-time {
    width: 180px;
  }

  &__results-column--value {
    text-align: center;
    width: 80px;
  }

  &__results-column--view-on-graph {
    text-align: center;
    width: 140px;
  }

  &__results-container {
    height: 182px;
    background: #fff;
    overflow-y: auto;
  }

  &__results-row {
    min-height: 26px;

    &--selectable {
      cursor: pointer;
    }
    
    &:nth-child(odd) {
      background-color: #fff;
    }

    &:nth-child(even) {
      background-color: #E9E9EE;
    }
  }

  &__results-row &__results-column--value,
  &__results-row &__results-column--view-on-graph {
    color: #037AFF;
  }
}