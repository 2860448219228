@import '_variables';

$previous-page-link-text-color: #fff;

.previous-navigation {
  background-color: #2C4141;

  &__link {
    text-decoration: none;
    color: $previous-page-link-text-color;
  }   

  &__arrow-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(~images/previous_page_icon.png) no-repeat center center;
    background: url(~images/previous_page_icon.svg) no-repeat center center/20px 20px;
    margin-right: $spacing;
  }

  &__text {
    font-size: 20px;
    color: $previous-page-link-text-color;
  }
}
