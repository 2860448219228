@import '_mixins';
@import '_variables';

label,
.label
{
  font-size: 20px;
  color: #6F6F6F;
  margin-bottom: $spacing;
  display: block;

  &--wrapper {
    margin: 0;
  }

  &--edit-enabled {
    font-size: 16px;
    color: #000;
  }
}

input[type=text],
input[type=email],
input[type=number]
{
  box-sizing: border-box;
  line-height: 1.25;
  font-size: 16px;
  padding: 7px 10px;
  border: 1px solid $default-border-color;
  max-width: 100%;

  @include border-radius(5px);

  &.numerical-input {
    max-width: 100px;
    text-align: center;
  }

  &.error {
    border-color: red;
  }

  &.full-width {
    width: 100%;
  }

  &.textbox-wide {
    width: 80%;
  }
}

input[type=text],
input[type=email],
input[type=button],
button
{
  &.inline {
    padding: 0.5em;
    box-sizing: border-box;
  }
}

.datepicker-wrapper > div
{
  display: inline-block;
  vertical-align: bottom;
}

.datepicker
{
  width: 145px;
  background: url(../images/datepicker_icon.svg) no-repeat center right 8px;
}

.timepicker
{
  width: 95px;
  background: url(../images/timepicker_icon.svg) no-repeat center right 8px;
}