@import '_variables';
@import '_mixins';

.loco-selection {
  &__wrapper {
    float: left;
    width: 415px;
    padding-right: 155px;
  }

  &__input-wrapper
  {
    background-color: #f3f3f3;
    padding: $spacing $spacing/2;
    border: 1px solid $default-border-color;
  }

  &__selection-count {
    color: #000;
    font-style: italic;
    font-size: 16px;
  }

  &__clear-selection {
    float: right;
    background: none;
    border: none;
    outline: none;
    color: $hyperlink-color;
    font-size: 14px;
  }
}

.selected-locos {
  &__wrapper {
    float: left;
    padding-left: 105px;
    border-left: 1px solid $default-border-color;
  }
}

.loco-list {
  max-height: 515px;
  overflow-y: auto;
  border: 1px solid $default-border-color;

  &__item {
    padding: $spacing $spacing*1.5;
  }

  &__item-label {
    color: #6F6F6F;
    font-size: 15px;
    display: inline-block;
    margin: 0;
  }

  input[type=checkbox],
  &__item-label {
    vertical-align: middle;
  }

  input[type=checkbox]:checked + &__item-label {
    color: #000;
  }
}