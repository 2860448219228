@mixin border-radius ($radius: 5px) {
  border-radius: $radius;
  background-clip: padding-box;
}

@mixin gradient ($startColor, $endColor) {
  background-image: linear-gradient($startColor, $endColor);
}

@mixin opacity($percentage) {
  opacity: ($percentage / 100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $percentage + ")";
  filter: alpha(opacity=$percentage);
}

// Image asset helper
$images-base-path: '~images';

@function image($file) {
  @return url($images-base-path + '/' + $file);
}